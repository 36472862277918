import { apiUrl } from "./scripts";

let loading = false;

export function updateInquiryCart(product_id, quantity) {
    if (!product_id) {
        return
    }

    const cartLoader = $('.cart-loader');

    cartLoader.fadeIn();

    return new Promise((resolve, reject) => {
        $.ajax({
            contentType: 'application/x-www-form-urlencoded',
            url: `${ apiUrl }/update-inquiry-cart`,
            type: 'POST',
            data: {
                'product_id': product_id,
                'quantity': quantity
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', auth.nonce);
            },
            success: function (response) {
                cartLoader.fadeOut();
                if (response.success) {
                    updateInquiryCartSummary(response);
                } else {
                    console.log(response);
                    reject(response);
                }
                resolve(response);
            },
            error: function (response) {
                cartLoader.fadeOut();
                console.log(response);
                reject(response);
            }
        });
    })
}

export function removeFromInquiry(product_id) {
    const buttons = document.querySelectorAll('.remove-inquiry-item');

    buttons.forEach(button => {
        button.addEventListener('click', async (e) => {
            e.preventDefault();

            const productId = button.dataset.productId;

            if (!productId) {
                return
            }

            await updateInquiryCart(productId, 0);

            removeInquiryCartItem(product_id);
        })
    });
}

function updateInquiryCartSummary(data) {
    const action = data.action;
    const totals = data.totals;
    const total = document.querySelector('.cart-summary .cart_total-alt');
    const delivery = document.querySelector('.cart-summary .cart_delivery-alt');
    const totalWithDelivery = document.querySelector('.cart-summary .cart_total_w_delivery-alt');
    const productLine = document.querySelector('.woodline-cart-inquiry .woocommerce-cart-form__cart-item[data-key="' + data.productId + '"]')
    const subtotal = productLine.querySelector('.product-subtotal');
    const tax = productLine.querySelector('.product-tax');

    if (action === 'add') {

        if (data.productInfo && data.productInfo != '0,00 €' && subtotal) {
            subtotal.innerText = data.productInfo;
        } else {
            subtotal.innerText = '-';
        }

        if (data.tax && data.tax != '0,00 €' && tax) {
            tax.innerText = data.tax;
        } else {
            tax.innerText = '-';
        }
    } else {
        removeInquiryCartItem(data.productId);
    }

    if (total) {
        total.innerText = totals.total;
    }

    if (delivery) {
        delivery.innerText = totals.fees;
    }

    if (totalWithDelivery) {
        totalWithDelivery.innerText = totals.total_w_fees;
    }

    if (action === 'refresh') {
        location.reload();
    }

}

function removeInquiryCartItem(id) {
    const productLine = document.querySelector('.woodline-cart-inquiry .woocommerce-cart-form__cart-item[data-key="' + id + '"]')

    if (productLine) {
        productLine.remove();
    }
}

export function removeInquiryProducts() {
    const form = $('#remove-inquiry-products');
    const spinner = $('.lds-dual-ring');

    form.submit(e => {
        e.preventDefault();

        if (loading) {
            return
        }

        spinner.removeClass('hidden');
        loading = true;

        $.ajax({
            contentType: false,
            url: `${ apiUrl }/remove-inquiries`,
            type: 'POST',
            processData: false,
            cache: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', auth.nonce);
            },
            success: function (response) {
                if (response.redirectUrl) {
                    window.location = response.redirectUrl
                }
                spinner.addClass('hidden');
                loading = false;
            },

            error: function (response) {
                //console.log(response);
                loading = false;
                spinner.addClass('hidden');
            }
        });
    })
}
