export function activateModal() {
    $('.w-modal-toggle').on('click', function (e) {
        e.preventDefault();
        const allModals = $('.w-modal');
        const modalTarget = $(this).data('modal');
        const modal = $(`#${ modalTarget }`);
        const pageMask = $('#page-mask');
        const root = $('html');

        if (modal) {
            allModals.each(mdl => {
                allModals[mdl].classList.remove('active');
                allModals[mdl].style.display = 'none';
            });

            if (window.modalDismiss) {
                window.modalDismiss.off('click');
                delete window.modalDismiss;
            }

            modal.fadeIn();
            modal.addClass('active');
            pageMask.fadeIn();
            root.css({ height: '100vh', overflowY: 'hidden' })

            if (modal.hasClass('active')) {
                window.modalDismiss = $(document).on('click', function (e) {
                    if (!(($(e.target).closest(modal).length > 0) || ($(e.target).closest(".w-modal-toggle").length > 0))) {
                        modal.fadeOut();
                        modal.removeClass('active');
                        pageMask.fadeOut();
                        root.removeAttr('style');
                    }
                });
            }

        } else {
            console.log('Modal with provided id was not found');
        }

    });
}
