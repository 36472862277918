import { loginUser } from './auth';
import { apiUrl } from './scripts';

export function guestOrUser() {
    const toggle = $('.checkout-page form.checkout input[type="radio"]');
    const guestTab = $('.checkout-page form.checkout .guest');
    const loggedInTab = $('.checkout-page form.checkout .logged-in');

    toggle.each(function () {
        $(this).change(function () {
            if ($(this).val() === 'login') {
                guestTab.addClass('hidden');
                loggedInTab.removeClass('hidden');
            } else {
                loggedInTab.addClass('hidden');
                guestTab.removeClass('hidden');
            }
        });
    });
}

export function loginIfGuest() {
    loginUser('.checkout.woocommerce-checkout', '.login-btn', window.location.origin + '/checkout');
}

export function toggleCheckoutTab() {
    const tabs = $('.checkout-page .checkout .checkout-form-block');

    tabs.each(function () {
        $(this).mousedown(function (e) {
            if (!$(this).hasClass('active')) {
                handlePaymentTypes();
                tabs.each(function () {
                    $(this).removeClass('active');
                });

                if (!$(this).hasClass('active')) {
                    $(this).addClass('active');
                }
            }
            if (e.target === $(this).find('.next-tab')[0]) {
                handlePaymentTypes();
                const parentBlock = $(this);
                const parentBlockId = parentBlock.prop('id').match(/[0-9]+/g)[0];
                const intId = parseInt(parentBlockId);

                if (intId !== tabs.length) {
                    tabs.each(function () {
                        $(this).removeClass('active');
                    });

                    $(`#chk-${ intId + 1 }`).addClass('active');
                }
            }
        });
    });
}

export function loginOnCheckout() {
    const form = $('.checkout.woocommerce-checkout');
    const submitButton = form.find('input[type="submit"]');
    let registerProcessed = false;

    submitButton.on('click', function (e) {
        if (registerProcessed) {
            return;
        }

        e.preventDefault();

        grecaptcha.ready(function () {
            grecaptcha.execute('6LdGEKEkAAAAADQ--zqXUzAyKbpYBOik2NaTzukh', { action: 'submit' }).then(function (token) {

                $('#g-token-checkout').val(token);

                $.ajax({
                    contentType: 'application/x-www-form-urlencoded',
                    url: `${ apiUrl }/register-or-login`,
                    data: form.serialize(),
                    type: 'POST',
                    success: function (response) {
                        if (response.success) {
                            registerProcessed = true;
                            submitButton.trigger('click');
                        } else {
                            console.log(response);
                        }
                    },
                    error: function (response) {
                        console.log(response);
                    }
                });

            });
        });
    });
}

function handlePaymentTypes() {
    const deliveryTypeInputs = $('input[name="shipping_method[0]"]');

    if (deliveryTypeInputs) {
        deliveryTypeInputs.each(el => {
            if ($(deliveryTypeInputs[el]).attr('id') === 'shipping_method_0_local_pickup2') {
                if (!$(deliveryTypeInputs[el]).is(":checked")) {
                    $('.wc_payment_method.payment_method_cod').hide();
                    $('#payment_method_paysera').prop('checked', true);
                } else {
                    $('.wc_payment_method.payment_method_cod').show();
                }
            }
        })
    }
}
