function setCookie(cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = "cookieconsent=" + JSON.stringify(cvalue) + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function hideCookieHolder() {
    $('.cookies-holder').addClass('hidden');
    $('.cookies-options').removeClass('active');
}

export function initCookies() {
    const cookieConsent = getCookie('cookieconsent');
    let cookies = {
        necessary_cookies: true
    };

    if (!cookieConsent) {
        $('.cookies-holder').removeClass('hidden');
    }

    $('.cookie-inputs input[name="functional_cookies"]').change(function () {
        delete cookies['functional_cookies'];
        if ($(this).prop('checked')) {
            cookies['functional_cookies'] = true;
        }
    });

    $('.cookie-inputs input[name="analytic_cookies"]').change(function () {
        delete cookies['analytic_cookies'];
        if ($(this).prop('checked')) {
            cookies['analytic_cookies'] = true;
        }
    });

    $('.cookie-inputs input[name="analytic_cookies_2"]').change(function () {
        delete cookies['analytic_cookies_2'];
        if ($(this).prop('checked')) {
            cookies['analytic_cookies_2'] = true;
        }
    });

    $('#cookie-accept-custom').on('click', function () {
        hideCookieHolder();
        setCookie(cookies);
    });

    $('#cookie-close-all').on('click', function () {
        hideCookieHolder();
        setCookie({ necessary_cookies: true });
    });

    $('#cookie-accept-all, #cookie-accept-all-2').on('click', function () {
        hideCookieHolder();
        cookies['functional_cookies'] = true;
        cookies['analytic_cookies'] = true;
        cookies['analytic_cookies_2'] = true;
        setCookie(cookies);
    })
}

export function toggleCookieOptions() {
    $('.cookies-options .close').on('click', function () {
        $('.cookies-options').removeClass('active');
    });

    $('.toggle-cookie-options').on('click', function (e) {
        e.preventDefault();
        $('.cookies-options').addClass('active');
    })
}

export function toggleReadMore() {
    $('.cookies-read-more').on('click', function (e) {
        e.preventDefault();
        const collapsedContent = $(this).parent().parent().find('.collapsed-content');

        if (!collapsedContent.hasClass('active')) {
            collapsedContent.css({ 'max-height': collapsedContent.prop('scrollHeight') + 10 + 'px' })
        } else {
            collapsedContent.removeAttr('style');
        }

        collapsedContent.toggleClass('active');
    })
}
