let spin = false;
import { apiUrl } from './scripts';
import { clearErrors, parseErrors } from './auth';

export function remindPassword() {
    $('#remind-password').on('submit', function (e) {
        e.preventDefault();

        const form = $(this);
        const email = form.find('input[name="email"]').val();
        const msg = form.find('.input-success');
        const spinner = $('.lds-dual-ring');

        clearErrors();

        msg.html('');

        spinner.removeClass('hidden');

        if (spin) {
            return;
        }

        spin = true;

        $.ajax({
            contentType: 'application/x-www-form-urlencoded',
            url: `${apiUrl}/remind-password`,
            type: 'POST',
            data: { 'email': email },
            success: function (response) {
                if (response.success) {
                    msg.html(response.message);
                    spin = false;
                    spinner.addClass('hidden');
                } else {
                    spin = false;
                    spinner.addClass('hidden');
                    parseErrors(response.errors, form);
                }
            },
            error: function (response) {
                spin = false;
                spinner.addClass('hidden');
                alert(response.error);
            }
        });
    })
}
