let loading = false;
let addingToCart = false;
export const apiUrl = `${ window.location.origin }/wp-json/api`;

import { editUserInformation, login, logout, registerUser, saveBillingInformation, parseErrors, clearErrors } from './auth';
import { removeInquiryProducts, updateInquiryCart, removeFromInquiry } from './inquiryCart';
import { activateModal } from './modal';
import { remindPassword } from './remind';
import { initCookies, toggleCookieOptions, toggleReadMore } from './cookies';
import { guestOrUser, loginIfGuest, loginOnCheckout, toggleCheckoutTab } from './checkout';

function resetProducts() {
    const productsList = $('.products-list ul li');
    productsList.remove();
}

function addStickyHeader() {
    jQuery(window).scroll(function () {
        var header = jQuery('body');
        var scroll = jQuery(window).scrollTop();

        if (scroll >= 140) {
            header.addClass('sticky');
        } else {
            header.removeClass('sticky');
        }
    });
}

function loadDefaultProducts() {

    const links = $('.category-link');

    links.each(function () {
        $(this).removeClass('active');
    });

    links.first().addClass('active');

    const categoryId = links.first().data('cat_id');

    if (categoryId) {
        loadProduct(categoryId);
    }
}

function loadProduct(categoryId) {
    const noProductsErr = $('.no-products-error');
    const spinner = $('.lds-dual-ring');
    resetProducts();
    spinner.removeClass('hidden');

    if (!noProductsErr.hasClass('hidden')) {
        noProductsErr.addClass('hidden');
    }

    if (loading) {
        return;
    }

    loading = true;
    $.ajax({
        contentType: 'application/x-www-form-urlencoded',
        url: `${ apiUrl }/list-categories`,
        type: 'GET',
        data: { 'category_id': categoryId },
        success: function (response) {
            if (response.success) {
                const products = response.data;
                const productsList = $('.products-list ul');
                for (let i = 0; i < products.length; i++) {
                    const image = products[i].images.length ? products[i].images[0].src : '';
                    productsList.append("" +
                        "<li>" +
                        "<a href='" + products[i].permalink + "'>" +
                        "<img src='" + image + "' alt=''>" +
                        "<h5>" + products[i].name + "</h5>" +
                        "</a>" +
                        products[i].short_description +
                        "</li>" +
                        "");
                }
                loading = false;
                spinner.addClass('hidden');
            } else {
                // loadDefaultProducts();
                noProductsErr.removeClass('hidden');
                spinner.addClass('hidden');
                loading = false;
            }
        },
        error: function (response) {
            alert(response.error);
            spinner.addClass('hidden');
            loading = false;
        }
    });
}

function selectDefaultProductsInModal() {
    const allChildren = $('.products-modal__children .child');
    const parents = $('.products-modal__parents div a');

    allChildren.each(function () {
        $(this).removeClass('is-active');
    });

    if (parents.length === 0) {
        return;
    }

    const parentClass = parents.first().attr('class');

    if (!parentClass) {
        return;
    }

    let parentId;
    const indexFrom = parentClass.indexOf('-') + 1;
    parentId = parentClass.slice(indexFrom, parentClass.length);

    if (parentId) {
        const children = $('.products-modal__children .child.parent_id-' + parentId);
        if (children.length > 0) {
            children.each(function () {
                $(this).addClass('is-active');
            });
        }
    }
}

function navigateProductsModal() {
    const allChildren = $('.products-modal__children .child');
    const parents = $('.products-modal__parents div a');

    parents.on('mouseenter', function () {
        allChildren.each(function () {
            $(this).removeClass('is-active');
        });
        const parentClass = $(this).attr('class');
        let parentId;

        if (parentClass) {
            const indexFrom = parentClass.indexOf('-') + 1;
            parentId = parentClass.slice(indexFrom, parentClass.length).match(/([0-9])+/g)[0];
        }

        if (parentId) {
            const children = $('.products-modal__children .child.parent_id-' + parentId);
            if (children.length > 0) {
                children.each(function () {
                    $(this).addClass('is-active');
                });
            }
        }
    });
}

function toggleProductsModal() {
    const productsModal = $('.products-modal');
    const menu = $('#menu-top-menu .products');

    $('.menu-item.products').on('mouseenter', function () {
        productsModal.addClass('is-active');
        menu.addClass('toggled');
    });

    productsModal.on('mouseleave', function () {
        productsModal.removeClass('is-active');
        menu.removeClass('toggled');
        selectDefaultProductsInModal();
    });
}

function selectProductCategory() {
    const links = $('.category-link');

    links.on('click', function (e) {

        links.each(function () {
            $(this).removeClass('active');
        });

        e.preventDefault();
        const categoryId = $(this).data('cat_id');
        $(this).addClass('active');

        if (categoryId) {
            loadProduct(categoryId);
        }
    });
}

function initSlickSlider() {
    const elms = document.getElementsByClassName( 'splide' );
    const defaultConfig = {
        type: 'loop',
        perPage: 3,
        arrows: false,
        autoplay: 'play',
        gap: 10,
        padding: 0
    };

    for (let i = 0; i < elms.length; i++ ) {
        new Splide( elms[ i ], defaultConfig ).mount();
    }
}

function productPreview() {
    const galleryImages = $('.product-preview .product-gallery .product-gallery__image');

    galleryImages.each(function () {
        $(this).on('click', function () {

            galleryImages.each(function () {
                $(this).removeClass('is-active')
            });

            $(this).addClass('is-active');
            const id = $(this).attr('id').match(/[0-9]+/g)[0];
            const previewImg = $('.product-preview .single-product__image img#preview-img-' + id);

            $('.product-preview .single-product__image img').addClass('hidden');

            if (previewImg) {
                previewImg.removeClass('hidden');
            }

        });
    });
}

function toggleLoginModal() {
    $('.user-login').click((e) => {
        e.preventDefault();
        $('.login-modal').addClass('is-active');
    });

    $(document).click(function (event) {
        if (!$(event.target).closest(".login-modal").length && !$(event.target).is('.user-login')) {
            $("body").find(".login-modal").removeClass("is-active");
        }
    });
}

(function productFilter() {
    let debounce = null;

    $('.product-order-filter').multipleSelect({
        selectAll: false,
        width: 197
    }).change(function () {
        if ($(this).val() != 'empty') {
            $('#filterForm').submit();
        }
    });

    $('.order-filter').multipleSelect({
        selectAll: false,
        width: 180
    }).change(function () {
        if ($(this).val() != 'empty') {
            $('#filterForm').submit();
        }
    });

    $('.tag-filter').click(function () {
        clearTimeout(debounce);

        debounce = setTimeout(function () {
            $('#filterForm').submit();
        }, 500);
    });

    $('.attribute-filter').multipleSelect({
        selectAll: false,
        width: 180,
        textTemplate: function ($el) {
            const element = $el.html();
            const count = element.match(/\(([^)]+)\)/);
            if (!count) {
                return $el.html()
            }
            const rawText = element.replace(count[0], '');
            return `<span class="filter_counter"><span class="filter_counter__number">${ count[1] }</span></span>${ rawText }`;
        }
    }).change(function () {
        $('#filterForm').submit();
    });
}());

function convertToUrlString(obj) {
    var s = "";
    for (var key in obj) {
        if (s != "") {
            s += "&";
        }
        s += (key + "=" + encodeURIComponent(obj[key]));
    }
    return s;
}

function getParams(url = window.location) {

    // Create a params object
    let params = [];

    new URL(url).searchParams.forEach(function (val, key) {
        params.push({ [key]: val })
    });

    return params;
}

function updateCart(cartItem, quantity) {
    const cartLoader = $('.cart-loader');

    cartLoader.fadeIn();

    $.ajax({
        contentType: 'application/x-www-form-urlencoded',
        url: `${ apiUrl }/update-cart-quantity`,
        type: 'POST',
        data: {
            'cartItemKey': cartItem,
            'quantity': quantity
        },
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-WP-Nonce', auth.nonce);
        },
        success: function (response) {
            if (response.success) {
                cartLoader.fadeOut();
                const cartItem = $(`[data-key='${ response.key }']`);

                $('.cart_total').html(`€ ${response.cartTotal}`);
                $('.cart_delivery').html(`€ ${response.shipping}`);
                $('.cart_total_w_delivery').html(`€ ${response.cartTotal}`);

                cartItem.children('.product-tax').html(`€ ${ response.tax }`)
                cartItem.children('.product-subtotal').html(`€ ${ response.total }`)
                // updateCartIndicator(response.separate_items);
            } else {
                cartLoader.fadeOut();
                alert(response.msg);
            }
        },
        error: function (response) {
            cartLoader.fadeOut();
        }
    });
}

function clearCache() {
    $.ajax({
        contentType: 'application/x-www-form-urlencoded',
        url: `${ apiUrl }/clear-cache`,
        type: 'POST',
        success: function (response) {
        },
        error: function (response) {
        }
    });
}

function changeCartQuantity() {
    let timeout = null;

    $('.product-quantity .input-step-up').click(function (e) {
        e.preventDefault;

        const stepUpInput = $(this);
        const cartItem = $(this).parents('.woocommerce-cart-form__cart-item').data('key');
        const input = $(this).parent().find('input[type="number"]');

        input[0].stepUp(1);

        let this_val = $(this).parent().find('input[type="number"]');
        let this_square = $(this).parents('tr').find('div.square').html();
        let this_square_result = $(this).parents('tr').find('span.square');
        let this_cubic = $(this).parents('tr').find('div.cubic').html();
        let this_cubic_result = $(this).parents('tr').find('span.cubic');
        //this_val[0].stepUp(1);
        if (this_square) {
            this_square_result.html((this_square * this_val.val()).toFixed(4));
        }
        if (this_cubic) {
            this_cubic_result.html((this_cubic * this_val.val()).toFixed(4));
        }

        const quantity = input.val();

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            if (stepUpInput.hasClass('input-step-up-alt')) {
                updateInquiryCart(cartItem, quantity);
            } else {
                updateCart(cartItem, quantity)
            }
        }, 500)

    });

    $(document).on("click", ".product-quantity .input-step-down", function (e) {
        e.preventDefault;
        const cartItem = $(this).parents('.woocommerce-cart-form__cart-item').data('key');
        const input = $(this).parent().find('input[type="number"]');
        const minValue = input.prop('min');
        const stepDownInput = $(this);

        if (input.val() > minValue) {
            input[0].stepDown(1);

            let this_val = $(this).parent().find('input[type="number"]');
            let this_square = $(this).parents('tr').find('div.square').html();
            let this_square_result = $(this).parents('tr').find('span.square');
            let this_cubic = $(this).parents('tr').find('div.cubic').html();
            let this_cubic_result = $(this).parents('tr').find('span.cubic');
            //this_val[0].stepDown(1);
            if (this_square) {
                this_square_result.html((this_square * this_val.val()).toFixed(4));
            }
            if (this_cubic) {
                this_cubic_result.html((this_cubic * this_val.val()).toFixed(4));
            }

            const quantity = input.val();

            clearTimeout(timeout);

            timeout = setTimeout(function () {
                if (stepDownInput.hasClass('input-step-down-alt')) {
                    updateInquiryCart(cartItem, quantity);
                } else {
                    updateCart(cartItem, quantity)
                }
            }, 500);

        } else {
            if ($(this).hasClass('input-step-down-alt')) {
                updateInquiryCart(cartItem, 0);
            } else {
                $(this).parents('.woocommerce-cart-form__cart-item').find('.product-remove a').trigger('click');
                clearCache();
                // updateCartIndicator(0, true);
            }
        }
    })
}

function updateCartIndicator(items = 0, subtract = false) {
    const indicator = $('.cart-block .indicator');

    if (subtract) {
        const presentVal = indicator.html();

        if (presentVal > 1) {
            indicator.html(presentVal - 1);
            return;
        } else {
            indicator.html('');
            indicator.hide();
            return;
        }
    }

    if (Number.isInteger(items) && items > 0) {
        indicator.removeAttr('style');
        indicator.html(items);
    } else {
        indicator.hide();
    }
}

function customNumberInputs() {
    const stepUp = $('.products__table .input-step-up');
    const stepDown = $('.products__table .input-step-down');

    stepUp.on('click', function (e) {
        e.preventDefault;
        let this_val = $(this).parent().find('input[type="number"]');
        let this_square = $(this).parents('.product-page').find('div.square').html();
        let this_square_result = $(this).parents('.product-page').find('span.square');
        let this_cubic = $(this).parents('.product-page').find('div.cubic').html();
        let this_cubic_result = $(this).parents('.product-page').find('span.cubic');
        this_val[0].stepUp(1);
        if (this_square) {
            this_square_result.html((this_square * this_val.val()).toFixed(4));
        }
        if (this_cubic) {
            this_cubic_result.html((this_cubic * this_val.val()).toFixed(4));
        }
    });

    stepDown.on('click', function (e) {
        e.preventDefault;
        let this_val = $(this).parent().find('input[type="number"]');
        let this_square = $(this).parents('.product-page').find('div.square').html();
        let this_square_result = $(this).parents('.product-page').find('span.square');
        let this_cubic = $(this).parents('.product-page').find('div.cubic').html();
        let this_cubic_result = $(this).parents('.product-page').find('span.cubic');
        this_val[0].stepDown(1);
        if (this_square) {
            this_square_result.html((this_square * this_val.val()).toFixed(4));
        }
        if (this_cubic) {
            this_cubic_result.html((this_cubic * this_val.val()).toFixed(4));
        }
    });
}

function setProductModalActiveCategory() {
    const bodyClassList = $('body').attr('class');
    const match = bodyClassList.match(/term-([0-9])+/g);
    let termId = null;

    if (match) {
        termId = match[0].match(/([0-9])+/g)[0];
    } else {
        return;
    }

    const product = $('.products-modal .products-modal__parents .cat_id-' + termId)
    if (product) {
        product.addClass('is-active');
        product.parent().addClass('is-active');
    }
}

function appendCartData(data) {
    const cartData = data.cart;
    const productData = data.product;

    const productMeta = $('.added-to-cart .product-info .product-meta');
    const price = productMeta.find('.product-meta__price');
    const oldPrice = productMeta.find('.product-meta__old-price');
    const title = productMeta.find('.product-meta__title');
    const amount = productMeta.find('.product-meta__amount');
    const productImg = $('.added-to-cart .product-info .product-img img');

    const cartInfo = $('.added-to-cart .cart-info');
    const cartQuantity = cartInfo.find('.cart-info__amount-value');
    const cartTotalValueBeforeTaxes = cartInfo.find('.cart-info__price-value');
    const cartShippingFee = cartInfo.find('.cart-info__delivery-value');
    const cartTotalValue = cartInfo.find('.cart-info__total-value');

    cartQuantity.empty();
    cartTotalValueBeforeTaxes.empty();
    cartShippingFee.empty();
    cartTotalValue.empty();

    cartTotalValueBeforeTaxes.append(cartData.total ? `${cartData.total} €` : '- €');
    cartQuantity.append(cartData.items);
    cartShippingFee.append(cartData.shipping ? `${cartData.shipping} €` : '- €');
    cartTotalValue.append((cartData.cartTotal) <= 0 ? '- €' : `${cartData.cartTotal} €`);

    productImg.attr('src', '');
    price.empty();
    oldPrice.empty();
    oldPrice.removeClass('hidden');
    title.empty();
    amount.find('.product-meta__amount-value').empty();

    if (productData.oldPrice) {
        price.append(productData.price ? `€ ${productData.price}` : '€ -');
        oldPrice.append(productData.oldPrice ? `€ ${productData.oldPrice}` : '€ -');
    } else {
        price.append(productData.price ? `€ ${productData.price}` : '€ -');
        oldPrice.addClass('hidden');
    }
    title.append(productData.title);
    amount.find('.product-meta__amount-value').append(' ' + productData.quantity);
    productImg.attr('src', productData.img);
}

function addToCart() {
    const cartSubmit = $('.cart button[type="submit"]');

    cartSubmit.on('click', function (e) {
        e.preventDefault();

        if (addingToCart) {
            return;
        }

        let data = {
            quantity: $(this).parent().find('input[name="quantity"]').val(),
            variationId: $(this).parent().find('input[name="variation_id"]').val(),
            productId: $(this).parent().find('input[name="product_id"]').val(),
            checkoutDisabled: $(this).parent().find('input[name="checkout_disabled"]').val(),
        };

        $(this).parents('.product-page').find('.cart-count').html(data.quantity);

        $(this).parents('.product-page').addClass('product-page-cart');

        addingToCart = true;

        $.ajax({
            contentType: 'application/x-www-form-urlencoded',
            url: `${ apiUrl }/add-to-cart`,
            type: 'POST',
            data: {
                'data': data,
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', auth.nonce);
            },
            success: function (response) {
                addingToCart = false;
                if (response.success) {
                    if (response.product && response.cart) {
                        appendCartData(response);
                        enableCartModal();
                        // updateCartIndicator(response.cart.separate);
                    } else {
                        enableCartModal(true);
                        appendEnquiryCartData(response.inquiryProduct, response.inquiryCart);
                    }
                } else {
                    alert(response.msg);
                }
            },
            error: function (response) {
                addingToCart = false;
            }
        });
    });
}

function appendEnquiryCartData(product, cart) {
    const addedToCartHolder = $('.added-to-cart-alt');

    if (!addedToCartHolder) {
        return;
    }

    const productImg = $('.product-img img');
    const price = addedToCartHolder.find('.product-meta__price');
    const oldPrice = addedToCartHolder.find('.product-meta__old-price');
    const title = addedToCartHolder.find('.product-meta__title');
    const amount = addedToCartHolder.find('.product-meta__amount');

    const cartInfo = addedToCartHolder.find('.cart-info');
    const cartQuantity = cartInfo.find('.cart-info__amount-value');
    const cartTotalValueBeforeTaxes = cartInfo.find('.cart-info__price-value');
    const cartShippingFee = cartInfo.find('.cart-info__delivery-value');
    const cartTotalValue = cartInfo.find('.cart-info__total-value');

    cartQuantity.empty();
    cartTotalValueBeforeTaxes.empty();
    cartShippingFee.empty();
    cartTotalValue.empty();

    cartTotalValueBeforeTaxes.append(cart.total ? cart.total : '-'  + ' €');
    cartQuantity.append(cart.count);
    cartShippingFee.append(cart.fees ? cart.fees : '-' + ' €');
    cartTotalValue.append(cart.total_w_fees <= 0 ? '-  €' : cart.total_w_fees + ' €');

    // resetting values
    productImg.attr('src', '');
    price.empty();
    oldPrice.empty();
    oldPrice.removeClass('hidden');
    title.empty();
    amount.find('.product-meta__amount-value').empty();

    // applying values
    if (product.oldPrice) {
        price.append(product.price ? `€ ${product.price}` : '€ -');
        oldPrice.append(product.oldPrice ? `€ ${product.oldPrice}` : '€ -');
    } else {
        price.append(product.price ? `€ ${product.price}` : '€ -' );
        oldPrice.addClass('hidden');
    }
    productImg.attr('src', product.image_src);
    title.append(product.title);
    amount.find('.product-meta__amount-value').append(' ' + product.quantity);
}

function productTabs() {
    const productTabs = $('.product-tab');
    const tabInfo = $('.tab-info');

    productTabs.each(function () {
        $(this).on('click', function () {

            productTabs.each(function () {
                $(this).removeClass('is-active');
            });
            const data = $(this).attr('id');
            $(this).addClass('is-active');

            tabInfo.each(function () {
                $(this).removeClass('is-active');

                if (data === $(this).data('tab-info')) {
                    $(this).addClass('is-active');
                }
            });
        })
    });
}

function expandContentInProducts() {
    const singleProductRow = $('.single-product-row');
    const singleProductRowHeight = singleProductRow.height();
    const childColumn = $('.single-product-row .single-product-col-md-8').prop('scrollHeight');
    const overflowHider = $('.overflow-hider');
    const productsTable = $('.products__table').prop('scrollHeight');

    let initialMaxHeight = 970;

    if (Math.round(singleProductRowHeight) >= childColumn) {
        overflowHider.addClass('hidden');
    }

    if (productsTable > 600) {
        initialMaxHeight = 1400;
        singleProductRow.css({ 'max-height': initialMaxHeight + 'px'});
    }

    $('.overflow-hider .read-more-btn-desktop,.overflow-hider .read-less-btn-desktop').on('click', function (e) {
        e.preventDefault();
        const wrapper = $(this).parent().parent();
        const productRow = $(this).parents('.single-product-row');

        if (!wrapper.hasClass('expanded')) {
            productRow.css({ 'max-height': productRow.prop('scrollHeight') + 'px', 'overflow': 'visible'});
            wrapper.addClass('expanded');
        } else {
            productRow.css({ 'max-height': initialMaxHeight + 'px', 'overflow': 'hidden'});
            wrapper.removeClass('expanded');
        }
    });

    $('.overflow-hider .read-more-btn-mobile,.overflow-hider .read-less-btn-mobile').on('click', function (e) {
        e.preventDefault();
        const wrapper = $(this).parent().parent();
        const tabs = $('.product-info-tabs');

        if (!wrapper.hasClass('expanded')) {
            wrapper.addClass('expanded');
            tabs.addClass('product-info-tabs-active');
        } else {
            wrapper.removeClass('expanded');
            tabs.removeClass('product-info-tabs-active');
        }
    });
}

function expandContent() {
    let productDescriptionH;
    const productsTableEl = $('.products__table');
    const productsTableH = productsTableEl.prop('scrollHeight');
    const columnH = productsTableEl.parent().prop('scrollHeight');
    const singleProductMetaEl = $('.single-product-meta');
    const singleProductMetaH = singleProductMetaEl.length ? singleProductMetaEl.prop('scrollHeight') : 0;
    const singleProductFiltersEl = $('.single-product-filters');
    const singleProductFiltersH = singleProductFiltersEl.length ? singleProductFiltersEl.prop('scrollHeight') : 0;

    productDescriptionH = columnH - (singleProductMetaH + singleProductFiltersH + productsTableH) - 100;

    $('.limited-visibility .read-more-btn,.limited-visibility .read-less-btn').on('click', function (e) {
        e.preventDefault();
        const wrapper = $(this).parent().parent();
        const scrollHeight = wrapper.prop('scrollHeight');

        if (!wrapper.hasClass('expanded')) {
            wrapper.css({ 'max-height': scrollHeight + 60 + 'px' });
            wrapper.addClass('expanded');
        } else {
            wrapper.css({ 'max-height': productDescriptionH + 'px' });
            wrapper.removeClass('expanded');
        }
    });
}

function isEncoded(uri) {
    uri = uri || '';

    return uri !== decodeURIComponent(uri);
}

function dismissCartModal() {
    let cartModal = $('.added-to-cart:not(.added-to-cart-alt)');
    let alt = false;
    const dismissButton = $('.dismiss_cart_modal');
    const pageMask = $('#page-mask');
    const root = $('html');

    dismissButton.each(button => {

        dismissButton[button].addEventListener('click', function (e) {
            e.preventDefault();

            if (dismissButton[button].classList.contains('alt')) {
                alt = true;
            }

            if (alt) {
                cartModal = $('.added-to-cart-alt');
            }

            root.removeAttr('style');

            if (!pageMask.hasClass('hidden') && !cartModal.hasClass('hidden')) {
                pageMask.addClass('hidden');
                cartModal.addClass('hidden')
            }
        })
    })
}

function enableCartModal(alt = false) {
    let cartModal = $('.added-to-cart:not(.added-to-cart-alt)');

    if (alt) {
        cartModal = $('.added-to-cart-alt');
    }

    const pageMask = $('#page-mask');
    const root = $('html');

    root.css({ height: '100vh', overflowY: 'hidden' })

    if (cartModal && pageMask) {
        cartModal.removeClass('hidden')
        pageMask.removeClass('hidden');
    }
}

function dimensionConverter() {
    const btn = $('.dimensions_converter');

    btn.on('click', function (e) {
        e.preventDefault();
        if ($('.dimensions_converter.cubic').length) {
            if ($(this).hasClass('square')) {
                $('.dimensions_converter.cubic').removeClass('hidden');
                $('.dimensions_converter.square').addClass('hidden');
            } else {
                $('.dimensions_converter.square').removeClass('hidden');
                $('.dimensions_converter.cubic').addClass('hidden');
            }
        }
    });
}

function configLightbox() {
    lightbox.option({
        showImageNumberLabel: false,
        fadeDuration: 200
    })
}

function aboutUsVideo() {
    window.URL = window.URL || window.webkitURL;

    const video = document.getElementById('about-video');
    const playBtn = $('.about__video--play');

    playBtn.on('click', function (e) {
        video.play();

        const btn = $(this);

        btn.fadeOut();

        video.style.cursor = 'pointer';

        video.addEventListener('click', function (e) {
            video.pause();

            video.style.cursor = 'initial';

            btn.fadeIn();
        });
    });
}

function configLeafletMaps() {
    if ($('#map1').length <= 0) {
        return
    }
    var map1 = L.map('map1').setView([ 55.68916200767595, 21.15758108465762 ], 16);

    var jankiskiuMarker = L.icon({
        iconUrl: `${ localData.image_path }/icons/taikos.png`,
        iconAnchor: [ 86, 40 ], // point of the icon which will correspond to marker's location
    });

    L.marker([ 55.68916200767595, 21.15758108465762 ], { icon: jankiskiuMarker }).addTo(map1);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/light-v10',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoidG9teXNzeiIsImEiOiJjbDI1dDUxczIwY3ZlM2pucGQ1NnJ1dXZvIn0.r0XNlyggzneu9ps6ksi9wQ'
    }).addTo(map1);

}

function submitOrder() {
    $('.checkout.woocommerce-checkout .proceed-order').on('click', function () {
        const form = $('.checkout.woocommerce-checkout');

        clearErrors();

        $.ajax({
            contentType: 'application/x-www-form-urlencoded',
            url: `${ apiUrl }/proceed-order`,
            type: 'POST',
            data: form.serialize(),
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', auth.nonce);
            },
            success: function (response) {
                if (response.success) {

                } else {
                    parseErrors(response.errors, form);
                }

            },
            error: function (response) {
            }
        });

    });
}

function sendRequestModal() {
    const modal = $('.product-request-modal');
    const form = modal.find('form');
    const checkbox = form.find('input[type="checkbox"]');
    const submitBtn = form.find('input[type="submit"]');
    const spinner = modal.find('.lds-dual-ring');
    const files_data = form.find('#file-input');

    checkbox.on('change', function (e) {
        if ($(this).is(':checked')) {
            submitBtn.prop('disabled', false);
        } else {
            submitBtn.prop('disabled', true);
        }
    });

    files_data.change(function (e) {
        const files = e.target.files;

        if (files.length > 1) {
            form.find('.attach-file-text').addClass('hidden');
            form.find('.multiple-files').removeClass('hidden');
            form.find('.multiple-files .multiple-files__number').html(files.length)
        } else {
            form.find('.attach-file-text').html(files[0].name);
        }
    })

    submitBtn.on('click', function (e) {
        e.preventDefault();

        if (loading) {
            return;
        }

        const form_data = new FormData(form[0]);

        $.each($(files_data), function (i, obj) {
            $.each(obj.files, function (j, file) {
                form_data.append('files[' + j + ']', file);
            })
        });

        spinner.removeClass('hidden');

        loading = true;

        clearErrors();

        $.ajax({
            contentType: false,
            url: `${ apiUrl }/send-product-request`,
            type: 'POST',
            processData: false,
            cache: false,
            data: form_data,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', auth.nonce);
            },
            success: function (response) {
                if (response.success) {
                    form.hide();
                    modal.find('h2').show();

                    setTimeout(() => {
                        if (response.redirectUrl) {
                            window.location = response.redirectUrl;
                        }
                    }, 500)
                } else {
                    parseErrors(response.errors, form);
                }
                spinner.addClass('hidden');
                loading = false;
            },

            error: function (response) {
                loading = false;
                spinner.addClass('hidden');
            }
        });
    })
}

function sendRequestModalSingle() {
    const modal = $('.product-request-modal-single');
    const form = modal.find('form');
    const checkbox = form.find('input[type="checkbox"]');
    const submitBtn = form.find('input[type="submit"]');
    const spinner = modal.find('.lds-dual-ring');
    const files_data = form.find('#file-input');

    checkbox.on('change', function (e) {
        if ($(this).is(':checked')) {
            submitBtn.prop('disabled', false);
        } else {
            submitBtn.prop('disabled', true);
        }
    });

    files_data.change(function (e) {
        const files = e.target.files;

        if (files.length > 1) {
            form.find('.attach-file-text').addClass('hidden');
            form.find('.multiple-files').removeClass('hidden');
            form.find('.multiple-files .multiple-files__number').html(files.length)
        } else {
            form.find('.attach-file-text').html(files[0].name);
        }
    })

    submitBtn.on('click', function (e) {
        e.preventDefault();

        if (loading) {
            return;
        }

        const form_data = new FormData(form[0]);

        $.each($(files_data), function (i, obj) {
            $.each(obj.files, function (j, file) {
                form_data.append('files[' + j + ']', file);
            })
        });

        spinner.removeClass('hidden');

        loading = true;

        clearErrors();

        $.ajax({
            contentType: false,
            url: `${ apiUrl }/send-product-single`,
            type: 'POST',
            processData: false,
            cache: false,
            data: form_data,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', auth.nonce);
            },
            success: function (response) {
                if (response.success) {
                    form.hide();
                    modal.find('h2').show();
                } else {
                    parseErrors(response.errors, form);
                }
                spinner.addClass('hidden');
                loading = false;
            },

            error: function (response) {
                loading = false;
                spinner.addClass('hidden');
            }
        });
    })
}

function fixActiveMenu() {
    if ($('body').is('.tax-product_cat, .single-product, .page-template-page-all-products')) {
        $('#menu-top-menu li.products').addClass('current-menu-item');
    }
}

function mobileMenu() {
    $(".mobile-menu").click(function (e) {
        e.preventDefault();

        $(this).toggleClass('active');
        $('.nav-elements').toggleClass('active');
    });
}

function disableMobileMenuOnScroll() {
    const initialPos = $(document).scrollTop();

    window.scrollEvent = jQuery(window).scroll(function () {
        if (Math.abs($(document).scrollTop() - initialPos) >= 100) {
            $("body").removeClass('mobile-menu-active');
            window.scrollEvent.off('scroll');
        }
    })
}

function variationsPaging() {
    $(".woodline-product-pagination .page-numbers").click(function (e) {
        e.preventDefault();
        var this_href = $(this).attr('href').replace('#', '');
        $(".woodline-product-pagination .page-numbers").removeClass('current');
        $(this).addClass('current');
        $(".product-page").removeClass('page-active');
        $("." + this_href).addClass('page-active');
    });
}

function caclMetrics() {
    $(".products__table .number-input .qty.input-qty:not('.qty-alt')").on('change keyup keydown', function () {
        let this_val = $(this).val();
        let this_square = $(this).parents('.product-page').find('div.square').html();
        let this_square_result = $(this).parents('.product-page').find('span.square');
        let this_cubic = $(this).parents('.product-page').find('div.cubic').html();
        let this_cubic_result = $(this).parents('.product-page').find('span.cubic');
        if (this_square) {
            this_square_result.html((this_square * this_val).toFixed(4));
        }
        if (this_cubic) {
            this_cubic_result.html((this_cubic * this_val).toFixed(4));
        }
    });
}

function caclMetricsCart() {
    $(".product-quantity .quantity .number-input .qty.input-qty:not('.qty-alt')").on('change keyup keydown', function () {
        let this_val = $(this).val();
        let this_square = $(this).parents('tr').find('div.square').html();
        let this_square_result = $(this).parents('tr').find('span.square');
        let this_cubic = $(this).parents('tr').find('div.cubic').html();
        let this_cubic_result = $(this).parents('tr').find('span.cubic');
        if (this_square) {
            this_square_result.html((this_square * this_val).toFixed(4));
        }
        if (this_cubic) {
            this_cubic_result.html((this_cubic * this_val).toFixed(4));
        }
    });
}

function delete_cookie( name, path, domain ) {
    if( get_cookie( name ) ) {
        document.cookie = name + "=" +
            ((path) ? ";path="+path:"")+
            ((domain)?";domain="+domain:"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

function get_cookie(name){
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
}

function preserveHistory() {
    const links = document.querySelectorAll('.products__item--link');

    links.forEach(link => {
        link.addEventListener('click', () => {
            const url = window.location.href;

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const search = urlParams.get('s');

            if (search) {
                delete_cookie('backUrl', '/', location.host)
                return;
            }

            var d = new Date();
            d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toGMTString();
            document.cookie = "backUrl=" + url + ";" + expires + ";path=/";
        });
    })
}

function changeUpdateCartTotals() {
    $('body').on('updated_cart_totals', function(e) {
        if ($('.woocommerce-cart-form .woocommerce-cart-form__cart-item.cart_item').length <= 0) {
            $('.default-product-list').hide();
            $('.cart-summary.default-cart').hide();
        }
    });
}

function showOrderDetails() {
    $(".order-detailed a.details").click(function (e) {
        e.preventDefault();
        const order_id = $(this).attr('href');
        $(order_id).toggleClass('info-active');
    });
}

$(document).ready(function () {
    // loadDefaultProducts();
    // selectProductCategory();
    showOrderDetails();
    initSlickSlider();
    addStickyHeader();
    toggleLoginModal();
    navigateProductsModal();
    toggleProductsModal();
    selectDefaultProductsInModal();
    addToCart();
    customNumberInputs();
    setProductModalActiveCategory();
    productPreview();
    productTabs();
    expandContent();
    changeCartQuantity();
    dimensionConverter();
    configLightbox();
    configLeafletMaps();
    submitOrder();
    aboutUsVideo();
    sendRequestModal();
    sendRequestModalSingle();
    fixActiveMenu();
    mobileMenu();
    variationsPaging();
    caclMetrics();
    caclMetricsCart();
    removeInquiryProducts();
    preserveHistory();
    changeUpdateCartTotals();
    activateModal();
    remindPassword();
    dismissCartModal();
    initCookies();
    toggleCookieOptions();
    toggleReadMore();
    expandContentInProducts();
    removeFromInquiry();
    // Auth
    editUserInformation();
    registerUser();
    logout();
    login();
    saveBillingInformation();
    // Checkout
    guestOrUser();
    toggleCheckoutTab();
    loginIfGuest();
    // loginOnCheckout();
});
