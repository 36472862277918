import { apiUrl } from './scripts';
let loader = false;

export function parseErrors(errors, form) {
    if (!errors) {
        return;
    }

    for (let key in errors) {
        if (errors.hasOwnProperty(key)) {
            let value = errors[key];

            if (form) {
                form.find("input[name=" + key + "]").parent().find('.input-err').html(value);
                form.find("select[name=" + key + "]").parent().find('.input-err').html(value);
                form.find("textarea[name=" + key + "]").parent().find('.input-err').html(value);
            }
        }
    }
}

export function clearErrors() {
    $('.input-err').each(function (index, item) {
        $(item).html('');
    });
}

export function logout() {
    $('.logout').on('click', function (e) {
        e.preventDefault();
        const spiner = $('.lds-dual-ring');

        spiner.removeClass('hidden');

        $.ajax({
            contentType: 'application/x-www-form-urlencoded',
            url: `${apiUrl}/logout`,
            type: 'POST',
            success: function (response) {
                if (response.success) {
                    spiner.addClass('hidden');
                    window.location.href = `${window.location.origin}`;
                }
            },
            error: function (response) {
                spiner.addClass('hidden');
                alert('logged out')
            }
        });
    });
}

export function editUserInformation() {
    const form = $('.profile-information__form');

    form.find('button[type="button"]').on('click', function (e) {
        e.preventDefault();

        const spiner = $('.lds-dual-ring');
        const msg = $('.input-success');

        spiner.removeClass('hidden');

        clearErrors();

        if (loader) {
            return;
        }

        loader = true;

        msg.html('');

        grecaptcha.ready(function () {
            grecaptcha.execute('6LdGEKEkAAAAADQ--zqXUzAyKbpYBOik2NaTzukh', {action: 'submit'}).then(function (token) {
                $('#g-token').val(token);

                $.ajax({
                    contentType: 'application/x-www-form-urlencoded',
                    url: `${apiUrl}/edit-profile`,
                    type: 'POST',
                    data: form.serialize(),
                    success: function (response) {
                        if (response.success) {
                            loader = false;
                            spiner.addClass('hidden');
                            msg.html(response.message);
                        } else {
                            loader = false;
                            spiner.addClass('hidden');
                            if (response.errors) {
                                parseErrors(response.errors, form);
                            } else if (response.error) {
                                alert(response.error);
                            }
                        }
                    },
                    error: function (response) {
                        loader = false;
                        spiner.addClass('hidden');
                        console.log(response);
                    }
                });

            });
        });

    });
}

export function loginUser(formSelector, submitSelector, redirectUrl = null) {
    const form = $(formSelector);
    form.find(submitSelector).on('click', function (e) {
        const spin = $('.lds-dual-ring');
        e.preventDefault;
        clearErrors();

        if (loader) {
            return;
        }

        loader = true;

        spin.removeClass('hidden');

        grecaptcha.ready(function () {
            grecaptcha.execute('6LdGEKEkAAAAADQ--zqXUzAyKbpYBOik2NaTzukh', {action: 'submit'}).then(function (token) {
                $('#g-token').val(token);

                $.ajax({
                    contentType: 'application/x-www-form-urlencoded',
                    url: `${apiUrl}/login-user`,
                    type: 'POST',
                    data: form.serialize(),
                    success: function (response) {
                        console.log(response)
                        if (response.success) {

                            if (!redirectUrl) {
                                redirectUrl = response.redirectUrl;
                            }

                            loader = false;
                            spin.addClass('hidden');
                            window.location.href = `${redirectUrl}`;
                        } else {
                            loader = false;
                            spin.addClass('hidden');
                            if (response.errors) {
                                parseErrors(response.errors, form);
                            } else if (response.error) {
                                alert(response.error);
                            }
                        }
                    },
                    error: function (response) {
                        loader = false;
                        spin.addClass('hidden');
                        console.log(response);
                    }
                });

            });
        });
    });
}

export function login() {
    loginUser('.login-user', '.submit-login');
}

export function registerUser() {
    const form = $('.register-new-user');
    form.find('.submit-new-user').on('click', function (e) {
        const spin = $('.lds-dual-ring');
        e.preventDefault;

        clearErrors();

        if (loader) {
            return;
        }

        loader = true;

        spin.removeClass('hidden');

        grecaptcha.ready(function () {
            grecaptcha.execute('6LdGEKEkAAAAADQ--zqXUzAyKbpYBOik2NaTzukh', {action: 'submit'}).then(function (token) {
                $('#g-token').val(token);

                $.ajax({
                    contentType: 'application/x-www-form-urlencoded',
                    url: `${apiUrl}/register-user`,
                    type: 'POST',
                    data: form.serialize(),
                    success: function (response) {
                        if (response.success) {
                            loader = false;
                            spin.addClass('hidden');
                            window.location.href = `${window.location.origin}`;
                        } else {
                            loader = false;
                            if (response.errors) {
                                parseErrors(response.errors, form);
                                spin.addClass('hidden');
                            } else if (response.error) {
                                spin.addClass('hidden');
                                alert(response.error);
                            }
                        }
                    },
                    error: function (response) {
                        spin.addClass('hidden');
                        loader = false;
                        console.log(response);
                    }
                });

            });
        });
    });
}

export function saveBillingInformation() {
    const form = $('.profile-addresses__form');
    const spinner = $('.lds-dual-ring');
    const msg = $('.input-success');

    form.on('submit', function (e) {
        e.preventDefault();

        if (loader) {
            return;
        }

        loader = true;

        msg.html('');

        spinner.removeClass('hidden');

        $.ajax({
            contentType: 'application/x-www-form-urlencoded',
            url: `${apiUrl}/save-billing-information`,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                spinner.addClass('hidden');
                loader = false;
                if (response.success) {
                    msg.html(response.message);
                } else {

                }
            },
            error: function (response) {
                spinner.addClass('hidden');
                loader = false;
                console.log(response);
            }
        });
    })
}

$(document).ready(function () {
    editUserInformation();
    registerUser();
    logout();
    login();
    saveBillingInformation();
});
